<template>
  <tr :id="rowId" v-if="!!fee">
    <td>
      <span class="link">{{ fee.price }}</span
      >&nbsp;دينار
    </td>
    <td>
      <span class="link">{{ fee.max }}</span
      >&nbsp;دينار
    </td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary" :target="`#${editId}`">
        <i class="fas fa-pen"></i>
      </modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteId}`">
        <i class="fas fa-trash-alt"></i>
      </modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editId"
        title="تعديل تسعيرة ونش"
        confirm-text="تعديل"
        @confirmed="clickById(`edit-fee-submit${fee.id}`)"
      >
        <form @submit.prevent="update">
          <input type="hidden" name="id" :value="fee.id" />
          <input type="hidden" name="_method" value="PUT" />
          <div class="row">
            <div class="col-sm-3">يدفع</div>
            <div class="col-sm-9">
              <form-input
                :id="`price${fee.id}`"
                name="price"
                type="number"
                placeholder="يدفع"
                :value="fee.price"
              ></form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">سعر المزاد</div>
            <div class="col-sm-9">
              <form-input
                :id="`max${fee.id}`"
                name="max"
                type="number"
                placeholder="سعر المزاد"
                :value="fee.max"
              ></form-input>
            </div>
          </div>
          <button
            hidden
            type="submit"
            :id="`edit-fee-submit${fee.id}`"
          ></button>
        </form>
      </confirm-model>
      <items-destroyer
        title="حذف تسعيرة ونش"
        :target-id="deleteId"
        :url="`${defaultUrl}/${fee.id}`"
        :row-id="`fee${fee.id}`"
        @deleted="deleted"
      >
        <span>هل أنت من حذف سعر المزاد</span>&nbsp;<span class="link">{{
          fee.max
        }}</span
        >&nbsp;؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import Storage from "@/common/Storage";

export default {
  components: { ItemsDestroyer, FormInput, ConfirmModel, ModalBtn },
  props: { fee: { required: true } },
  inject: ["defaultUrl"],
  emits: ["update", "deleted"],
  computed: {
    deleteId() {
      if (!this.fee) return "";
      return `delete-fee${this.fee.id}`;
    },
    editId() {
      if (!this.fee) return "";
      return `edit-fee${this.fee.id}`;
    },
    rowId() {
      if (!this.fee) return "";
      return `fee${this.fee.id}`;
    },
  },
  methods: {
    clearStorage() {
      Storage.removeStartWith("invoice-auction");
    },
    update(e) {
      this.clearStorage();
      return this.$emit("update", e);
    },
    deleted(id) {
      this.clearStorage();
      return this.$emit("deleted", id);
    },
  },
};
</script>