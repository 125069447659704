<template>
  <div>
    <div class="w-100 text-right">
      <modal-btn
        btn-class="btn-sm btn-soft-danger"
        target="#add-fees"
        title="إضافة شريحة تأمين"
      >
        إضافة&nbsp;<i class="fa fa-plus"></i>
      </modal-btn>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered" :class="{ shadow: !emptyList }">
        <tr class="bg-bb">
          <th>يدفع</th>
          <th>
            <label>سعر المزاد</label>&nbsp;
            <small>(أقل من أو يساوي)</small>
          </th>
          <th>تعديل</th>
        </tr>
        <tr v-if="emptyList">
          <td colspan="3" class="font-weight-bolder">لا يوجد</td>
        </tr>
        <fee-item
          v-else
          @deleted="deleted"
          @update="update"
          v-for="item in list"
          :key="item.id"
          :item="item"
          :fee="item"
        ></fee-item>
      </table>
    </div>
    <ThePaginator
      :total="total"
      :url="defaultUrl"
      :last-page="lastPage"
      :links="links"
      @link-changed="setLink"
    />
    <teleport to="#app">
      <confirm-model
        title="إضافة شريحة تأمين"
        confirm-text="إضافة"
        confirm-btn="bg-bb"
        header-bg="bg-bb"
        target-id="add-fees"
        title-color="text-dark"
        @confirmed="clickById('add-insurance-btn')"
      >
        <form @submit.prevent="store">
          <div class="row">
            <div class="col-sm-3">يدفع</div>
            <div class="col-sm-9">
              <form-input
                id="price"
                type="number"
                placeholder="يدفع"
              ></form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">سعر المزاد</div>
            <div class="col-sm-9">
              <form-input
                id="max"
                type="number"
                placeholder="سعر المزاد"
              ></form-input>
            </div>
          </div>
          <button hidden id="add-insurance-btn"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import ThePaginator from "@/components/UI/ThePaginator";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import FeeItem from "@/components/Pages/Companies/FeeItem";
import Storage from "@/common/Storage";

export default {
  components: { FeeItem, FormInput, ConfirmModel, ModalBtn, ThePaginator },
  mixins: [pagination],
  inject: ["formData", "company"],
  provide() {
    return {
      defaultUrl: this.defaultUrl,
    };
  },
  computed: {
    defaultUrl() {
      return "/fees";
    },
    storageName() {
      return `fees${this.company}`;
    },
  },
  methods: {
    async store(e) {
      if (!this.company) return this.companyNotSpecified();
      const formData = new FormData(e.target);
      formData.set("company", this.company);
      const response = await http.send("fees", formData);
      if (http.responseAccepted(response)) {
        this.setResponseData(response, "#add-fees");
        $_(".form-control").val("");
      }
    },
    async update(e) {
      if (!this.company) return this.companyNotSpecified();
      const formData = new FormData(e.target);
      const id = formData.get("id");
      formData.set("company", this.company);
      http.inputsId = id;
      const response = await http.send(`fees/${id}`, formData);
      if (http.responseAccepted(response))
        this.setResponseData(response, `#edit-fee${id}`);
      http.inputsId = "";
    },
    companyNotSpecified() {
      return http.popupMessage("error", "لم يتم تحديد الشركة");
    },
    setResponseData(response, modal) {
      // REMOVE CACHED INVOICE DATA
      Storage.removeStartWith("invoice-auction");
      if (!response) return false;
      this.setPagination(response.data);
      this.hideModal(modal);
      http.popupMessage("success", messages.saved);
    },
  },
  async created() {
    this.storage = true;
    await this.setList();
  },
};
</script>
